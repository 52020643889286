/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";

import { StoreProvider } from "./src/context/store.js";

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);

// exports.shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition
// }) => {
//   if (location.hash) {
//     return false;
//   }

//   return true;
// };
